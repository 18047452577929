import React from "react"
import PropTypes from "prop-types"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { css } from "@emotion/react"
import { colors as theme, units } from "./variables"
import useTheme from "../hooks/useTheme"

const NavigationLink = ({ to, target = "_blank", ariaLabel, children }) => {
  const isDarkTheme = useTheme()
  const colors = isDarkTheme ? theme.dark : theme.light

  const styles = {
    root: css({
      alignContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      boxSizing: "border-box",
      color: colors.grey1,
      cursor: "pointer",
      display: "flex",
      fill: colors.grey1,
      font: "inherit",
      justifyContent: "center",
      lineHeight: "1",
      textDecoration: "none",
      transition: units.speed.slow,
      whiteSpace: "nowrap",
      width: "100%",
      minWidth: "4rem",
      minHeight: "4rem",
      outline: `0.2rem solid transparent`,
      outlineOffset: "0.2rem",

      "&:hover": {
        color: colors.primary,
        fill: colors.primary,
      },

      "&:focus-visible": {
        color: colors.primary,
        fill: colors.primary,
        outlineColor: colors.primary,
      },

      "&:active": {
        transform: "scale(0.96)",
      },
    }),
  }

  return (
    <OutboundLink
      css={styles.root}
      href={to}
      target={target}
      aria-label={ariaLabel}>
      {children}
    </OutboundLink>
  )
}

NavigationLink.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  ariaLabel: PropTypes.string,
}

export default NavigationLink

import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"

const Icon = ({ name, inline = false, size }) => {
  const display = inline ? "inline" : "block"

  const styles = css({
    direction: "ltr",
    display,
    fontFamily: "Material Icons",
    fontFeatureSettings: "liga",
    fontSize: size !== undefined ? `${Number(size) * 0.1}rem` : "inherit",
    fontWeight: "normal",
    letterSpacing: "normal",
    lineHeight: "1",
    MozOsxFontSmoothing: "grayscale",
    textRendering: "optimizeLegibility",
    textTransform: "none",
    WebkitFontSmoothing: "antialiased",
    whiteSpace: "nowrap",
    wordWrap: "normal",
  })

  return <span css={styles}>{name}</span>
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  size: PropTypes.string,
}

export default Icon

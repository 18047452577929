import React from "react"
import { Instagram, LinkedIn } from "./SocialIcons"
import { Link } from "gatsby"
import NavigationLink from "./NavigationLink"
import { css } from "@emotion/react"
import { colors as theme, typography, units } from "./variables"
import { rgba } from "emotion-rgba"
import useTheme from "../hooks/useTheme"

const Logo = () => {
  const isDarkTheme = useTheme()
  const colors = isDarkTheme ? theme.dark : theme.light
  const avatarSize = "4rem"

  const styles = {
    root: css({
      alignItems: "center",
      color: colors.grey1,
      display: "flex",
      font: typography.headline,
      lineHeight: avatarSize,
      textDecoration: "none",
      whiteSpace: "nowrap",
    }),
    image: css({
      borderRadius: "50%",
      height: avatarSize,
      marginRight: units.spacer.small,
      outline: "none",
      width: avatarSize,
      display: "block",
    }),
  }

  return (
    <Link to="/" css={styles.root} tabIndex="-1">
      <img
        css={styles.image}
        src="/images/me.png"
        alt="Marko Masnjak - Staff Product Designer"
      />
      Marko Masnjak
    </Link>
  )
}

const NavigationItems = () => {
  const styles = {
    root: css({
      alignItems: "center",
      boxSizing: "border-box",
      display: "flex",
      gap: units.spacer.small,
      justifyContent: "flex-end",
      listStyle: "none",
      margin: "0",
      maxWidth: "100%",
      padding: "0",
    }),
    option: css({
      margin: "0",
      padding: "0",
    }),
  }

  return (
    <ul css={styles.root}>
      <li css={styles.option}>
        <NavigationLink
          to="https://www.instagram.com/markomasnjak"
          ariaLabel="Instagram">
          <Instagram size="20" />
        </NavigationLink>
      </li>

      <li css={[styles.option, styles.last]}>
        <NavigationLink
          to="https://linkedin.com/in/markomasnjak"
          ariaLabel="LinkedIn">
          <LinkedIn size="20" />
        </NavigationLink>
      </li>
    </ul>
  )
}

const Navigation = () => {
  const isDarkTheme = useTheme()
  const colors = isDarkTheme ? theme.dark : theme.light
  const mediumScreen = `@media (min-width: ${units.breakpoint.medium})`
  const backdropFilterSupported =
    "@supports (backdrop-filter: blur(2rem)) or (-webkit-backdrop-filter: blur(2rem))"

  const styles = {
    root: css({
      alignItems: "center",
      backgroundColor: colors.background,
      border: "none",
      boxSizing: "border-box",
      display: "flex",
      fontWeight: "500",
      gap: units.spacer.large,
      justifyContent: "space-between",
      padding: units.spacer.medium,
      position: "sticky",
      top: "0",
      width: "100%",
      zIndex: "100",

      [mediumScreen]: {
        paddingLeft: units.spacer.large,
        paddingRight: units.spacer.large,
      },

      [backdropFilterSupported]: {
        backgroundColor: rgba(colors.background, 0.8),
        backdropFilter: "saturate(180%) blur(2rem)",
        webkitBackdropFilter: "saturate(180%) blur(2rem)",
      },
    }),
  }

  return (
    <nav id="top" css={styles.root}>
      <Logo />
      <NavigationItems />
    </nav>
  )
}

export default Navigation

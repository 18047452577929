import React from "react"
import Anchor from "./Anchor"
import { css } from "@emotion/react"
import { colors as theme, typography, units } from "./variables"
import useTheme from "../hooks/useTheme"

const Footer = () => {
  const isDarkTheme = useTheme()
  const colors = isDarkTheme ? theme.dark : theme.light
  const year = new Date().getFullYear()
  const mediumScreen = `@media (min-width: ${units.breakpoint.medium})`

  const styles = {
    root: css({
      padding: units.spacer.medium,

      [mediumScreen]: {
        paddingLeft: units.spacer.large,
        paddingRight: units.spacer.large,
      },
    }),
    content: css({
      borderTop: `0.1rem solid ${colors.grey5}`,
      font: typography.caption,
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: units.maxWidth,
      paddingTop: units.spacer.large,
    }),
  }

  return (
    <footer css={styles.root}>
      <div css={styles.content}>
        &copy; {year} Marko Masnjak. Designed and built by me in Chicago, IL
        with{" "}
        <Anchor href="https://www.gatsbyjs.org/" target="_blank">
          GatsbyJS
        </Anchor>{" "}
        and{" "}
        <Anchor href="https://emotion.sh/docs/introduction" target="_blank">
          Emotion
        </Anchor>
        , typeset in{" "}
        <Anchor
          href="https://www.schick-toikka.com/scto-grotesk-a"
          target="_blank">
          Scto Grotesk
        </Anchor>
        .
      </div>
    </footer>
  )
}

export default Footer
